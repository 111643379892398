import React, { Children, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { clamp } from '../../Lib/utils'
import { CircleButton } from '../CircleButton'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SideDrawer: React.FC<IProps> = ({
  children,
  size,
  title,
  activeSheet = 0,
  onClose,
  show = true,
  disableBackButton = false,
  onBackButtonClick = (): void => {},
}) => {
  const sideDrawerContainer = document.getElementById('sideDrawerContainer')
  const content = useRef<HTMLDivElement>(null)
  const [showOpen, setShowOpen] = useState<boolean>(false)

  const handleClose = useCallback((): void => {
    setShowOpen(false)
    setTimeout(onClose, parseInt(Styles['transition-duration'], 10))
  }, [onClose, setShowOpen])

  const handleBackClick = (): void => {
    if (activeSheet > 0) onBackButtonClick(activeSheet - 1)
  }

  const handleOverlayClick = ({ target, currentTarget }: React.MouseEvent): void => {
    const isSameElement = currentTarget === target
    const isChildOfCurrentTarget = currentTarget.contains(target as Node)
    const clickedOutside = isSameElement && isChildOfCurrentTarget

    if (clickedOutside) handleClose()
  }

  useEffect(() => {
    if (content.current) content.current.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activeSheet])

  useEffect(() => {
    if (!show) handleClose()
  }, [handleClose, show])

  useEffect(() => {
    setShowOpen(true)
  }, [])

  const numChildren = Children.count(children)
  const constrainedActiveSheet = clamp(activeSheet, 0, numChildren - 1)
  const isAtFirstSheet = activeSheet === 0
  const showBackButton = numChildren > 1 && !isAtFirstSheet && !disableBackButton

  if (!sideDrawerContainer) return null

  return createPortal(
    <div className={`${Styles.sideDrawer} ${showOpen ? Styles.isOpen : ''}`} onMouseDown={handleOverlayClick}>
      <div className={`${Styles.drawer} ${Styles[size]}`}>
        <div className={Styles.header}>
          <Typography className={Styles.title} variant="h1-large">
            {title}
          </Typography>

          <CircleButton iconName="cross" onClick={handleClose} className={Styles.closeButton} />

          <CircleButton
            iconName="arrow-left"
            onClick={handleBackClick}
            className={`${Styles.backButton} ${!showBackButton ? Styles.hide : ''}`}
          />
        </div>

        <div className={Styles.content} ref={content}>
          {Children.map(children, (sheet, index) => {
            return React.cloneElement(sheet, { isActive: index === constrainedActiveSheet })
          })}
        </div>
      </div>
    </div>,
    sideDrawerContainer
  )
}
