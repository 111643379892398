import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmModal, Typography } from '../../../../../../../Components'
import Styles from './styles.module.scss'

export const InviteEmployees: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  // FIXME: _usetButtonDisabled will be used in the next ticket
  const [buttonDisabled, _usetButtonDisabled] = useState<boolean>(false)

  const toggleConfirmModal = (): void => {
    setShowConfirmModal(!showConfirmModal)
  }

  return (
    <div>
      <Typography variant="body-2">
        {t('Select the budget groups below whose employees you want to send an automatic email invitation to.')}
      </Typography>
      {/* // FIXME: in the next ticket we need to add the disabled property to the button to make a disabled button possible */}
      <Button size="medium" onClick={toggleConfirmModal} className={Styles.button} disabled={buttonDisabled}>
        {t('Send invitation')}
      </Button>
      {showConfirmModal && (
        <ConfirmModal
          content={t('Send invitation email')}
          description={t('Send an invitation email to all invited employees within the selected budget groups.')}
          confirmButtonProps={{ onClick: toggleConfirmModal, content: t('Send invitation') }}
          onCancel={(): void => setShowConfirmModal(false)}
        />
      )}
    </div>
  )
}
