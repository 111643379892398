import { t } from 'i18next'

import { TIconName } from '../../../Components/Icon/iconNames'
import { IGetWrpmQuery, IV2BudgetGroup, IWrpmModalityEnum } from '../../../Lib/graphql'
import { IBudgetGroupOption, ITransformedWRPMRow } from './interfaces'

export const defaultBudgetGroupOption: IBudgetGroupOption = { label: t('All budgetgroups'), value: null }

export const getBudgetGroupOptions = (budgetGroups: IV2BudgetGroup[]): IBudgetGroupOption[] => {
  const options = budgetGroups
    .filter((item) => item.name && item.id)
    .map((item): IBudgetGroupOption => ({ label: item.name!, value: item.id! }))

  return [defaultBudgetGroupOption, ...options]
}

export const transformWRPMData = (WRPMData: IGetWrpmQuery): ITransformedWRPMRow[] => {
  const { wrpm } = WRPMData.reisbalansV2

  if (!wrpm || !wrpm.length) return []

  const transformedWRPMData = wrpm.map((item): ITransformedWRPMRow => {
    return {
      id: item?.modality?.value || '',
      kind: item?.modality?.value || null,
      humanized: item?.modality?.humanized || '',
      commute_kms: item?.statsPerModalityCommute?.totalKms || 0,
      commute_co2: item?.statsPerModalityCommute?.totalCo2 || 0,
      business_kms: item?.statsPerModalityBusiness?.totalKms || 0,
      business_co2: item?.statsPerModalityBusiness?.totalCo2 || 0,
    }
  })

  const cumulativeRow = transformedWRPMData.reduce(
    (acc, value): ITransformedWRPMRow => {
      acc.commute_kms += value.commute_kms
      acc.commute_co2 += value.commute_co2
      acc.business_kms += value.business_kms
      acc.business_co2 += value.business_co2

      return acc
    },
    {
      id: 'total',
      kind: null,
      humanized: t('Total'),
      commute_kms: 0,
      commute_co2: 0,
      business_kms: 0,
      business_co2: 0,
    }
  )

  return [cumulativeRow, ...transformedWRPMData]
}

export const mapModalityEnumToIconName = (value: IWrpmModalityEnum): TIconName => {
  switch (value) {
    case IWrpmModalityEnum.CAR_PETROL:
    case IWrpmModalityEnum.CAR_DIESEL:
    case IWrpmModalityEnum.CAR_HYBRID:
    case IWrpmModalityEnum.CAR_ELECTRIC:
    case IWrpmModalityEnum.CAR_OTHER:
      return 'car'

    case IWrpmModalityEnum.MOTORCYCLE_PETROL:
    case IWrpmModalityEnum.MOTORCYCLE_ELECTRIC:
      return 'motorcycle'

    case IWrpmModalityEnum.SCOOTER_PETROL:
    case IWrpmModalityEnum.SCOOTER_ELECTRIC:
      return 'scooter'

    case IWrpmModalityEnum.LEGS_OR_BICYCLE:
      return 'bicycle'

    case IWrpmModalityEnum.OV:
      return 'public-transport'

    default:
      return 'receipt'
  }
}

export const getDefaultBudgetGroupNames = (budgetGroups: IV2BudgetGroup[]): string[] => {
  return budgetGroups.filter((item) => item.name).map((item): string => item.name!)
}
