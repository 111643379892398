import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '../../../Components/FormComponents/Select'
import { Icon } from '../../../Components/Icon'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import { ITableConfig } from '../../../Components/Table/interfaces'
import { useGetBudgetGroupsQuery, useGetWrpmQuery } from '../../../Lib/graphql'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import { ProgressInvitedEmployees } from './Components/ProgressInvitedEmployees'
import { Survey } from './Components/Survey'
import {
  defaultBudgetGroupOption,
  getBudgetGroupOptions,
  getDefaultBudgetGroupNames,
  mapModalityEnumToIconName,
  transformWRPMData,
} from './helpers'
import { IBudgetGroupOption, ITransformedWRPMRow, IWRPMTableRow } from './interfaces'
import Styles from './styles.module.scss'

export const WRPMOverview: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?
  const [selectedOrganizationId, _usetSelectedOrganizationId] = useState<string>(`${organizationId}`)

  const environment = import.meta.env.REACT_APP_ENVIRONMENT
  const showProgressInvitedEmployees = environment !== 'production'

  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<IBudgetGroupOption>(defaultBudgetGroupOption)
  const [selectedBudgetGroupNames, setSelectedBudgetGroupNames] = useState<string[]>([])
  const [budgetGroupOptions, setBudgetGroupOptions] = useState<IBudgetGroupOption[]>([])
  const [defaultBudgetGroupNames, setDefaultBudgetGroupNames] = useState<string[]>([])

  const [tableData, setTableData] = useState<IWRPMTableRow[]>([])

  const [showSurvey, setShowSurvey] = useState<boolean>(false)

  const toggleSurvey = (): void => {
    setShowSurvey(!showSurvey)
  }

  const getKindCellContent = (item: ITransformedWRPMRow): JSX.Element => {
    return (
      <div className={Styles.modalityKindWrapper}>
        {item.kind ? <Icon name={mapModalityEnumToIconName(item.kind)} /> : ''} {item.humanized}
      </div>
    )
  }

  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    const tableRows: IWRPMTableRow[] = []

    transformedWRPMData.forEach((item) => {
      tableRows.push({
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: `${item.commute_kms} ${t('km')}`,
        commute_co2: `${item.commute_co2} ${t('gram')}`,
        business_kms: `${item.business_kms} ${t('km')}`,
        business_co2: `${item.business_co2} ${t('gram')}`,
      })
    })

    return tableRows
  }

  const { loading: loadingFirstQuery } = useGetBudgetGroupsQuery({
    variables: { organizationId: `${organizationId}` },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: (response) => {
      const { budgetGroupsForWrpm } = response?.reisbalansV2?.v2Organization || []

      if (budgetGroupsForWrpm && budgetGroupsForWrpm.length) {
        setBudgetGroupOptions(getBudgetGroupOptions(budgetGroupsForWrpm))

        const defaultBudgetGroupNamesArray = getDefaultBudgetGroupNames(budgetGroupsForWrpm)
        setDefaultBudgetGroupNames(defaultBudgetGroupNamesArray)
        setSelectedBudgetGroupNames(defaultBudgetGroupNamesArray)
      }
    },
    skip: !organizationId,
  })

  const { loading: loadingSecondQuery } = useGetWrpmQuery({
    variables: { organizationId: selectedOrganizationId, budgetGroupNames: selectedBudgetGroupNames },
    onCompleted: (response) => {
      const transformedWRPMData = transformWRPMData(response)
      const createdTableData = createTableData(transformedWRPMData)

      setTableData(createdTableData)
    },
    skip: !organizationId || selectedBudgetGroupNames.length < 1,
  })

  const handleBudgetGroupChange = (budgetGroup: IBudgetGroupOption): void => {
    setSelectedBudgetGroup(budgetGroup)

    budgetGroup === defaultBudgetGroupOption
      ? setSelectedBudgetGroupNames(defaultBudgetGroupNames)
      : setSelectedBudgetGroupNames([budgetGroup.label])
  }

  const getTableConfig = (): ITableConfig => {
    return {
      kind: {
        name: t('Kind'),
        width: '300px',
      },
      commute_kms: {
        name: t('Commute'),
        width: '200px',
      },
      commute_co2: {
        name: t('Commute CO2'),
        width: '200px',
      },
      business_kms: {
        name: t('Business'),
        width: '200px',
      },
      business_co2: {
        name: t('Business CO2'),
        width: '200px',
      },
    }
  }

  return (
    <PageContainer
      header={{
        title: t('Passenger mobility legislation'),
        showLine: true,
      }}
    >
      <Fragment>
        {showProgressInvitedEmployees && <ProgressInvitedEmployees onClick={toggleSurvey} />}

        {/* Added this div since the select component doesn't allow a decent override of the width, see RBW-1095 */}
        <div className={Styles.budgetGroupWrapper}>
          <Select
            id="budgetgroup"
            ariaLabel={t('Budgetgroup')}
            onChange={handleBudgetGroupChange}
            value={selectedBudgetGroup}
            options={budgetGroupOptions}
          />
        </div>

        <Table tableConfig={getTableConfig()} tableData={tableData} loading={loadingFirstQuery || loadingSecondQuery} />

        {showSurvey && <Survey onClose={toggleSurvey} />}
      </Fragment>
    </PageContainer>
  )
}
