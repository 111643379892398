import React, { FormEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Button'
import { Checkbox } from '../../../../../FormComponents/Checkbox'
import { Input } from '../../../../../FormComponents/Input'
import { ContentSection } from '../../../../../Modal/Components'
import { Typography } from '../../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ManualView: React.FC<IProps> = ({
  tableConfig,
  tableDisplaySettings,
  selectedTableDisplaySetting,
  onChange,
}): JSX.Element => {
  const { t } = useTranslation()
  const formRef = useRef(null)
  const inputFieldName = 'settingsLabel'

  const handleSettings = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (!formRef.current) return
    const data = Object.fromEntries(new FormData(formRef.current))

    const showFields: Array<string> = Object.keys(tableConfig).reduce((acc: Array<string>, key) => {
      if (data[key] === 'true' || tableConfig[key].required) acc.push(key)
      return acc
    }, [])

    const viewName = data[inputFieldName] as string
    onChange(showFields, viewName)
  }

  return (
    <ContentSection id="custom-set" title={t('Manual view')}>
      <Typography className={Styles.subTitle} variant="body-2">
        {t('Fields')}
      </Typography>

      <form ref={formRef} onSubmit={handleSettings}>
        <div className={Styles.checkboxContainer}>
          {Object.keys(tableConfig).map((field) => {
            if (field.match(/^__/)) return
            return (
              <div key={field} className={Styles.checkboxColumn}>
                <Checkbox
                  id={field}
                  name={field}
                  label={tableConfig[field].name}
                  disabled={tableConfig[field].required}
                  checked={tableDisplaySettings[selectedTableDisplaySetting].showFields.indexOf(field) !== -1}
                />
              </div>
            )
          })}
        </div>

        <Input
          id="settingsLabel"
          label={t('Do you want to save the view? Name the selection.')}
          name={inputFieldName}
          placeholder={t('e.g. Standard, Compact or Environmental')}
        />

        <Button type="submit" size="small" className={Styles.applyButton}>
          {t('Use')}
        </Button>
      </form>
    </ContentSection>
  )
}
