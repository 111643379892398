import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SideDrawer } from '../../../../../Components/SideDrawer'
import { Sheet } from '../../../../../Components/SideDrawer/components/Sheet'
import { InviteEmployees } from './Components/InviteEmployees'
import { IProps } from './interfaces'

export const Survey: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [activeSheet, setActiveSheet] = useState<number>(0)

  return (
    <SideDrawer
      title={t('Invite employees')}
      size="lg"
      onBackButtonClick={setActiveSheet}
      activeSheet={activeSheet}
      onClose={onClose}
      disableBackButton={activeSheet === 1}
    >
      <Sheet>
        <InviteEmployees />
      </Sheet>
    </SideDrawer>
  )
}
